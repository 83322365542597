<template>
    <!-- <h2 class="card-title fw-bold mb-1">Start your Sabenzi journey! 👋</h2> -->
    <p class="card-text mb-2 pt-2"><b>Make your talent management easy and fun!</b></p>
    <form class="auth-login-form mt-2" @submit.prevent="createAccount" v-if="!isAccountCreated">
        <div class="mb-1">
            <label :class="fv.name.msg?'text-danger':''" class="form-label" for="fv_name">
                Organization name
                <span v-if="fv.name.msg" > | {{fv.name.msg}}</span>
            </label>
            <input name="fv_form_token" value="" type="hidden" />
            <input class="form-control" :class="fv.name.msg?'border-danger':''" id="fv_name"
                   type="text" required v-model.trim="fv.name.val" placeholder="Enter organization name"
                   @keyup="validate.validateFormData(fv,'name')" aria-describedby="login-email" autofocus="" tabindex="1"
            />
        </div>
        <div class="mb-1">
            <label class="form-label" :class="fv.email.msg?'text-danger':''" for="fv_email">
                Email
                <span v-if="fv.email.msg" > | {{fv.email.msg}}</span>
            </label>
            <input class="form-control" id="fv_email" type="email" required v-model.trim="fv.email.val"
                   @keyup="validate.validateFormData(fv,'email')" placeholder="Enter email" aria-describedby="login-email"
                    :class="fv.email.msg?'border-danger':''" autofocus="" tabindex="1" />
        </div>
        <div class="mb-1">
            <div class="d-flex justify-content-between">
                <label class="form-label" :class="fv.password.msg?'text-danger':''" for="fv_password">
                    Password
                    <span v-if="fv.password.msg" > | {{fv.password.msg}}</span>
                </label>
            </div>
            <div class="input-group input-group-merge form-password-toggle">
                <input class="form-control form-control-merge" required id="fv_password"
                 :type="fv.password.showPassword?'text':'password'" v-model="fv.password.val"
                 :class="fv.password.msg?'border-danger':''"
                 placeholder="Enter Password" @keyup="validate.validateFormData(fv,'password')"
                 aria-describedby="login-password" min="8" max="32" tabindex="2" />
                 <span class="input-group-text cursor-pointer" @click="togglePasswordVisibility">
                    <i class="bi " :class="fv.password.showPassword?'bi-eye-slash-fill':'bi-eye-fill'"></i>
                </span>
            </div>
        </div>
        <div class="mt-1">
            <base-captcha :key="captcha" :captchaVal="captcha" ></base-captcha>
        </div>
        <div >
            <label v-if="fv.captcha.msg" class="form-label text-danger" >
                {{fv.captcha.msg}}
            </label>
            <input class="form-control"  type="text"
            required v-model="fv.captcha.val"  min="7" max="7" @keyup="validate.validateFormData(fv,'captcha')"
            placeholder="Enter the text above" aria-describedby="captcha" autofocus="" tabindex="1" />
        </div>

        <div class="col-12" v-if="fv.reqFeedback.status">

            <base-list-tag class="mt-1" :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
        </div>

        <base-button btnColor="primary" class="w-100 mt-2" tabindex="4" v-if="fv.showSubmit.val">
            Sign up
        </base-button>
        <base-spinner  v-else></base-spinner>

        <p class="text-center mt-2">
            <span>Already have an account?</span>
            <router-link to="/login" >
                <span>&nbsp; Sign in instead </span>
            </router-link>
        </p>
        <div class="divider my-2" >
            <div class="divider my-2">
                <div class="divider-text">or</div>
            </div>
            <div class="auth-footer-btn d-flex justify-content-center" >
                <a class="btn btn-google" href="#"><i class="bi bi-google"></i></a>
                <a class="btn btn-facebook" href="#"><i class="bi bi-facebook"></i></a>
                <a class="btn btn-twitter white" href="#"><i class="bi bi-twitter"></i></a>
                <a class="btn btn-linkedin" href="#"><i class="bi bi-linkedin"></i></a>
                <a class="btn btn-github" href="#"><i class="bi bi-github"></i></a>
            </div>
        </div>
    </form>

    <base-card-response class="mt-1" v-if="isAccountCreated">

        <template #header>Account successfully created. </template>
        <template #default>
            <p>
                Kindly check your email for a verification link.
                If you are not automatically redirected.
            </p>
            <p><router-link to="/account_update_profile"> <u style="color:#fff">Click this link </u></router-link></p>
        </template>

    </base-card-response>

</template>

<script>
import { ref,reactive,inject } from 'vue'
import { useStore } from 'vuex';
// import { useRouter } from 'vue-router';
import validators from '@/hooks/validation'

export default{

  name: 'CreateAuthSignUp',
  setup() {
    // get the global vuex store objects
    const store = useStore();
    // const router = useRouter();

    // get the validators
    const validate = validators();

    // get the axios object
    const axios = inject('axios');  // inject axios

    //get the urls
    const coreUrl = inject('coreUrl');

    // initiate token, csrf_token and captcha variables with blank values
    let token = "";
    let csrf_token = "";
    const captcha = ref("");

    const tokenData  = ref(false);
    const reqError  = ref("Loading sign up form....");



    // when the vue module is created, get the token value, using the try catch

            // use axios to get the data
            axios.get(coreUrl.backendUrl+'get_csrf_token',{withCredentials: true}).then((response) =>{
                // return the full token to the token variable
                token = response.data.data;
                tokenData.value = true;

                // make the first 43 characters, the csrf token
                csrf_token = token.substring(0,43)

                // make the last 5 characters, the captcha
                captcha.value = token.substring(43,48)


            }).catch((error)=>{
                // if an error occours, do this
                tokenData.value = false;
                const errorMsg = "Sign up form can't be loaded at this time. Try again";
                reqError.value = error.response.data.message.req_msg?errorMsg:errorMsg;
            })




     // create variables for the values to be submitted
    const fv = reactive({
        name : {status:false, val:"", msg:false, validate:["required",
                                                                 "safeSpaceInput",
                                                                 {option:"textMin",val:3},
                                                                 {option:"textMax",val:120}
                                                                ]},
        email : {status:false, val:"", msg:false, validate:["required","email"]},
        accountType : {status:false, val:"organization", msg:false, validate:["required"]},
        password : {status:false, val:"", msg:false, showPassword: false, validate:["required",
                                                                                      {option:"textMin",val:10},
                                                                                      {option:"textMax",val:64}
                                                                                     ]},
        captcha : {status:false, val:"", msg:false, validate:["required","alphaNumeric"]},
        reqFeedback : {status:false, val:"", msg:false},
        showSubmit : {status:false, val:true}
    })


    // toggle password visibility
    function togglePasswordVisibility(){
        fv.password.showPassword = !fv.password.showPassword;
    }

    // get the current status of the created account
    const isAccountCreated = ref(store.getters["auth/getAuthAuthentication"].isAccountCreated);

    // create the account using async
    async function createAccount(){

        // end the action of making a submission, and hide loader
        fv.showSubmit.val = false;

        // load the form data , and append all the necessary fields
        let formData = new FormData();
        formData.append("fv_name", fv.name.val);
        formData.append("fv_email", fv.email.val);
        formData.append("fv_password", fv.password.val);
        formData.append("fv_terms","read_accepted");
        formData.append("fv_csrf_token",csrf_token+fv.captcha.val);
        formData.append("fv_account_type", fv.accountType.val);
        formData.append("fv_account_credentials_source", "sabenzi");
        formData.append("fv_host_root_url", coreUrl.tmsUrl);


        // post the data using axios
        try{
            const response = await axios.post(
                coreUrl.backendUrl+'create_basic_account',
                formData,
                {
                    withCredentials: true,
                    headers:{
                       "Content-Type": "multipart/form-data"
                    }
                }
            );

            // If successful, get the resppnse data
            const responseData = response.data.data;

            const authToken = responseData.token;
            // update the vuex authentication data
            const authAuthentication = {
                            isAuthenticated : true,
                            isAccountCreated : true
                        }


            // update the vuex credentials data
            const authCredentials = {
                accountName: fv.name.val,
                accountEmail: fv.email.val,
                accountType : fv.accountType.val,
                accountId : responseData.account_id
            }

            // update the vuex permissions data
            const authPermissions = responseData.permissions


            if(response.data.status){
                // execute and update state for  token,authentication,credentials & permissions
                store.dispatch("auth/executeSetAuthToken",authToken );
                store.dispatch("auth/executeSetAuthAuthentication",authAuthentication);
                store.dispatch("auth/executeSetAuthCredentials",authCredentials);
                store.dispatch("auth/executeSetAuthPermissions",authPermissions);

                // update is account created status
                isAccountCreated.value = true;

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;

                window.location.replace(coreUrl.tmsUrl+'account_update_profile');
            }else{
                token =  response.data.data?response.data.data:"bad";
                csrf_token =  response.data.data?token.substring(0,43):"bad";
                captcha.value =  response.data.data?token.substring(43,48):"bad";

                // load the error to the appropriate field
                const fv_msg = response.data.message.fv_msg;
                const req_msg = response.data.message.req_msg;

                for(const key in fv_msg){
                    if(fv[key]){
                        fv[key]["status"] = false;
                        fv[key]["msg"] = fv_msg[key];
                    }
                }


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = req_msg;
                fv.reqFeedback.val = "danger";

                // end the action of making a submission, and hide loader
                fv.showSubmit.val = true;
            }

            // window.location.replace(coreUrl.tmsUrl+'account_update_profile');
            // router.push('account_update_profile');



        }catch(error){

            // redo the captcha sequence
            token =  error.response.data.data?error.response.data.data:"bad";
            csrf_token =  error.response.data.data?token.substring(0,43):"bad";
            captcha.value =  error.response.data.data?token.substring(43,48):"bad";

            // load the error to the appropriate field
            const fv_msg = error.response.data.message.fv_msg;
            const req_msg = error.response.data.message.req_msg;

            for(const key in fv_msg){
                if(fv[key]){
                    fv[key]["status"] = false;
                    fv[key]["msg"] = fv_msg[key];
                }
            }


            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = req_msg;
            fv.reqFeedback.val = "danger";

            // end the action of making a submission, and hide loader
            fv.showSubmit.val = true;

        }

    }

    return {
        fv,
        validate,
        tokenData,
        reqError,
        togglePasswordVisibility,
        createAccount,
        captcha,
        isAccountCreated
     }
  }
}
</script>
